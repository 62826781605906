import revive_payload_client_pEgnTtJG92 from "/data/richarne/hackaton-apps/hack24-images/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.6.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_4fjhq43y7jwenfcbiro3llmbsy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_GZ3FIX3S8v from "/data/richarne/hackaton-apps/hack24-images/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.6.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_4fjhq43y7jwenfcbiro3llmbsy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_1P2RmjQ0oH from "/data/richarne/hackaton-apps/hack24-images/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.6.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_4fjhq43y7jwenfcbiro3llmbsy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_WSmbiWyiQs from "/data/richarne/hackaton-apps/hack24-images/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.6.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_4fjhq43y7jwenfcbiro3llmbsy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_T8YU52QSVG from "/data/richarne/hackaton-apps/hack24-images/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.6.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_4fjhq43y7jwenfcbiro3llmbsy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_h4mzvI8QAw from "/data/richarne/hackaton-apps/hack24-images/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.6.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_4fjhq43y7jwenfcbiro3llmbsy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_8oyENpzJvn from "/data/richarne/hackaton-apps/hack24-images/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.6.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_4fjhq43y7jwenfcbiro3llmbsy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/data/richarne/hackaton-apps/hack24-images/.nuxt/components.plugin.mjs";
import prefetch_client_dGiebKhhCd from "/data/richarne/hackaton-apps/hack24-images/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.6.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_4fjhq43y7jwenfcbiro3llmbsy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import error_handler_kEP5FliEXj from "/data/richarne/hackaton-apps/hack24-images/plugins/error-handler.ts";
export default [
  revive_payload_client_pEgnTtJG92,
  unhead_GZ3FIX3S8v,
  router_1P2RmjQ0oH,
  payload_client_WSmbiWyiQs,
  navigation_repaint_client_T8YU52QSVG,
  check_outdated_build_client_h4mzvI8QAw,
  chunk_reload_client_8oyENpzJvn,
  components_plugin_KR1HBZs4kY,
  prefetch_client_dGiebKhhCd,
  error_handler_kEP5FliEXj
]