import { default as indexshEbGqjSwwMeta } from "/data/richarne/hackaton-apps/hack24-images/pages/admin/index.vue?macro=true";
import { default as sharedbqf3DShNm8Meta } from "/data/richarne/hackaton-apps/hack24-images/pages/admin/shared.vue?macro=true";
import { default as uploadSwAPrSCMdZMeta } from "/data/richarne/hackaton-apps/hack24-images/pages/admin/upload.vue?macro=true";
import { default as latesttpdtuZOz8TMeta } from "/data/richarne/hackaton-apps/hack24-images/pages/ai/latest.vue?macro=true";
import { default as selectOLDCh0Ljc4MQxMeta } from "/data/richarne/hackaton-apps/hack24-images/pages/ai/selectOLD.vue?macro=true";
import { default as shared3HVVYoaoYEMeta } from "/data/richarne/hackaton-apps/hack24-images/pages/ai/shared.vue?macro=true";
import { default as indexLgSCFGAWsbMeta } from "/data/richarne/hackaton-apps/hack24-images/pages/index.vue?macro=true";
export default [
  {
    name: "admin",
    path: "/admin",
    meta: indexshEbGqjSwwMeta || {},
    component: () => import("/data/richarne/hackaton-apps/hack24-images/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-shared",
    path: "/admin/shared",
    component: () => import("/data/richarne/hackaton-apps/hack24-images/pages/admin/shared.vue").then(m => m.default || m)
  },
  {
    name: "admin-upload",
    path: "/admin/upload",
    component: () => import("/data/richarne/hackaton-apps/hack24-images/pages/admin/upload.vue").then(m => m.default || m)
  },
  {
    name: "ai-latest",
    path: "/ai/latest",
    component: () => import("/data/richarne/hackaton-apps/hack24-images/pages/ai/latest.vue").then(m => m.default || m)
  },
  {
    name: "ai-selectOLD",
    path: "/ai/selectOLD",
    component: () => import("/data/richarne/hackaton-apps/hack24-images/pages/ai/selectOLD.vue").then(m => m.default || m)
  },
  {
    name: "ai-shared",
    path: "/ai/shared",
    component: () => import("/data/richarne/hackaton-apps/hack24-images/pages/ai/shared.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/data/richarne/hackaton-apps/hack24-images/pages/index.vue").then(m => m.default || m)
  }
]